.bg-bluee {
  background-color: #4b7ec3;
}

.drop-slot {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 8px;
  border-top: 1px solid #bbb;
}

.drop-slot:first-of-type {
  border-top: none;
}
